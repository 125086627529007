import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import flatten from 'lodash/flatten'

import { UsersState, usersSelector } from '../../hasura/slices/users'
import { User_users_user_roles_user_role_consultation_types } from '../../hasura/graphQlQueries/types/User'

import {
  ConsultationTypesState,
  consultationTypesSelector,
  deleteUserRoleConsultationTypeAction,
  fetchConsultationTypesAction,
  insertUserRoleConsultationTypeAction,
} from '../../hasura/slices/consultationTypes'

export default function ConsultationTypes() {
  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)
  const { consultationTypes }: ConsultationTypesState = useSelector(consultationTypesSelector)

  const consultationTypesForUser = flatten((user?.user_roles || []).map((r) => r.user_role_consultation_types))
  const userRole = user?.user_roles.find((r) => r.role === 'vet' && r.enterprise.id === user.organization.enterprise.id)

  useEffect(() => {
    if (!accessToken) return

    dispatch(fetchConsultationTypesAction(accessToken))
  }, [accessToken])

  const updateUserRoleConsultationType = (
    consultation_type_id: number,
    userRoleConsultationType?: User_users_user_roles_user_role_consultation_types
  ) => {
    if (!user || !accessToken || !userRole) return

    if (userRoleConsultationType) {
      dispatch(deleteUserRoleConsultationTypeAction(accessToken, userRoleConsultationType.id, user.id))
    } else {
      dispatch(insertUserRoleConsultationTypeAction(accessToken, { consultation_type_id, user_role_id: userRole.id }, user.id))
    }
  }

  return (
    <div className="mb-3">
      <p className="text-s bold mb-1">Modalities</p>

      <div className="d-flex flex-column gap-5px">
        {consultationTypes.map((consultationType) => {
          const userRoleConsultationType = consultationTypesForUser.find((c) => c.consultation_type.id === consultationType.id)
          const checked = Boolean(userRoleConsultationType)
          return (
            <div
              onClick={() => updateUserRoleConsultationType(consultationType.id, userRoleConsultationType)}
              key={consultationType.id}
              className={`d-flex align-items-center pointer ${checked ? '' : 'opacity-50'}`}
            >
              <input type="checkbox" id={`stat-${consultationType.id}`} checked={checked} />
              <p className="mb-0 ml-1">{consultationType.display_name}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
