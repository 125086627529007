import React, { useEffect, useState } from 'react'
import pick from 'lodash/pick'
import { Badge, Button, Input } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useTable } from 'react-table'

import { Icon, Table, EditableCheckbox, Header, HeaderCell, Cell, TextCell, Row } from '../common/Table'
import { User_users_organization_distribution_emails } from '../../hasura/graphQlQueries/types/User'
import { usersSelector, UsersState } from '../../hasura/slices/users'
import { validEmail } from '../../lib/helpers'

import {
  deleteDistributionEmailAction,
  distributionEmailsSelector,
  DistributionEmailsState,
  insertDistributionEmailAction,
  updateDistributionEmailAction,
} from '../../hasura/slices/distribution-emails'

// @ts-ignore
import deleteIcon from '../../lib/images/delete.png'

const defaultColumn = {
  Cell: TextCell,
  EditableCheckbox,
}

interface Props {
  columns: any
  data: User_users_organization_distribution_emails[]
  deleteDistributionEmail?: (id: number) => void
}

const MAX_DISTRIBUTION_EMAILS = 10
const updateKeys = ['id', 'send_ai_reports', 'send_human_reports', 'email']

export default function DistributionEmailsTable(props: Props) {
  const dispatch = useDispatch()

  const { columns, data } = props

  const [name, setName] = useState<string | undefined>()
  const [email, setEmail] = useState<string | undefined>()
  const [newAdminEmail, setNewAdminEmail] = useState<string | undefined>()

  const { accessToken, user }: UsersState = useSelector(usersSelector)
  const { loading }: DistributionEmailsState = useSelector(distributionEmailsSelector)

  useEffect(() => {
    if (newAdminEmail && validEmail(newAdminEmail)) {
      updateDistributionEmail(0, 'email', newAdminEmail)
    }
  }, [newAdminEmail])

  const addDistributionEmail = async () => {
    await dispatch(
      insertDistributionEmailAction(accessToken, { email, display_name: name, organization_id: user?.organization.id }, user!.id)
    )
    setEmail(undefined)
    setName(undefined)
  }

  const deleteDistributionEmail = (id: number) => dispatch(deleteDistributionEmailAction(accessToken, id, user!.id))

  const updateDistributionEmail = async (rowIndex: number, columnId: string, value: any) => {
    const variables = pick(data[rowIndex], updateKeys)
    // @ts-ignore
    variables[columnId] = value
    // @ts-ignore
    dispatch(updateDistributionEmailAction(accessToken, variables, user!.id))
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    defaultColumn,
    // @ts-ignore
    update: updateDistributionEmail,
  })

  return (
    <Table className="max-width-1200px" cellSpacing={0} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup: any, idx: number) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <HeaderCell
                key={idx}
                {...column.getHeaderProps({ style: { width: ['Email', 'Name'].includes(column.Header) && '250px' } })}
              >
                <Header>{column.render('Header')}</Header>
              </HeaderCell>
            ))}
            <HeaderCell />
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, idx: number) => {
          prepareRow(row)

          const id = row.original.id

          const isAdmin = idx === 0

          return (
            <Row key={idx} {...row.getRowProps()}>
              {row.cells.map((cell: any, idx2: number) => {
                const aiReports = cell.column.Header === 'AI Reports'
                const humanReports = cell.column.Header === 'Radiologist Reports'

                if (aiReports || humanReports) {
                  return (
                    <Cell key={idx2} {...cell.getCellProps()}>
                      {cell.render('EditableCheckbox')}
                    </Cell>
                  )
                } else if (cell.column.Header === 'Role') {
                  return (
                    <Cell key={idx2} {...cell.getCellProps()}>
                      <Badge className="min-width-100px" color={isAdmin ? 'dark' : 'secondary'}>
                        {isAdmin ? 'Admin' : 'Veterinarian'}
                      </Badge>
                    </Cell>
                  )
                } else if (cell.column.Header === 'Email') {
                  return (
                    <Cell key={idx2} {...cell.getCellProps()}>
                      {isAdmin ? (
                        <Input
                          className="data-hj-allow"
                          onChange={(e: any) => setNewAdminEmail(e.target.value)}
                          type="text"
                          value={newAdminEmail || cell.row.original.email}
                        />
                      ) : (
                        cell.render('Cell')
                      )}
                    </Cell>
                  )
                } else {
                  return (
                    <Cell key={idx2} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </Cell>
                  )
                }
              })}

              <Cell>
                {!isAdmin && <Icon disabled={loading} onClick={() => deleteDistributionEmail(id)} alt="delete" src={deleteIcon} />}
              </Cell>
            </Row>
          )
        })}

        {rows.length < MAX_DISTRIBUTION_EMAILS && (
          <tr>
            <Cell>
              <Input
                className="data-hj-allow"
                type="text"
                onChange={(e: any) => setName(e.target.value)}
                placeholder="Name"
                value={name || ''}
              />
            </Cell>

            <Cell>
              <Input
                className="data-hj-allow"
                type="text"
                onChange={(e: any) => setEmail(e.target.value)}
                placeholder="Email"
                value={email || ''}
              />
            </Cell>

            <Cell />
            <Cell />
            <Cell />

            <Cell>
              <Button
                className="width-100px"
                color="primary"
                disabled={!email || !validEmail(email) || loading}
                onClick={() => addDistributionEmail()}
              >
                Add
              </Button>
            </Cell>
          </tr>
        )}
      </tbody>
    </Table>
  )
}
