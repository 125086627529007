import * as Yup from 'yup'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Button, CustomInput } from 'reactstrap'
import { Formik, Form } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone'

import ConsultationTypes from '../components/settings/ConsultationTypes'
import DistributionEmailsTable from '../components/settings/DistributionEmailsTable'
import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import { FormikInput } from '../components/common/Input'
import { OrganizationEvent } from '../lib/organizationEventTypes'
import { createCustomerPortalAction } from '../hasura/slices/billing'
import { fetchUserAction, usersSelector, UsersState, updateUserAudioAlertsOnAction } from '../hasura/slices/users'
import { insertOrganizationEventForUserAction, updateOrganizationAction } from '../hasura/slices/organizations'
import { isAdmin, isEnterpriseAdmin, isUser, isVet, phoneRegExp } from '../lib/helpers'
import { setNotificationAction, NotificationId } from '../hasura/slices/notifications'
import { updateEnterpriseDefaultTurnaroundHoursAction, updateEnterpriseLogoAction } from '../hasura/slices/enterprises'
import { FormikRadioButton } from '../components/common/FormikRadioButton'

const TURNAROUND_TIMES = [
  { key: '24 hours', value: '24' },
  { key: '48 hours', value: '48' },
  { key: '72 hours', value: '72' },
]

export default function Settings() {
  const dispatch = useDispatch()

  const { accessToken, user, role, isRadimalEnterprise }: UsersState = useSelector(usersSelector)

  useEffect(() => {
    dispatch(insertOrganizationEventForUserAction(accessToken, OrganizationEvent.OpenedSettings, user?.organization.id))
  }, [])

  const handleFormSubmit = async (values: any) => {
    dispatch(setNotificationAction(NotificationId.SettingsUpdated))
    await dispatch(
      updateOrganizationAction(
        accessToken,
        user!.organization.id,
        values['displayPricing'],
        values['hospital'],
        values['pimsEmail'],
        values['phoneNumber'],
        user?.organization.number_of_vets || undefined
      )
    )
    await dispatch(updateUserAudioAlertsOnAction(accessToken, user!.id, values['audioAlertsOn']))
    const defaultTurnaroundHours = parseInt(values.defaultTurnaroundHours, 10)
    if (isAdmin(role) && defaultTurnaroundHours && defaultTurnaroundHours !== user!.organization.enterprise.default_turnaround_hours) {
      await dispatch(
        updateEnterpriseDefaultTurnaroundHoursAction(accessToken, user!.organization.enterprise.id, defaultTurnaroundHours)
      )
    }
    dispatch(fetchUserAction(accessToken, user!.id, false))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'display_name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'All AI Reports',
        accessor: 'send_ai_reports',
      },
      {
        Header: 'All Radiologist Reports',
        accessor: 'send_human_reports',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
    ],
    []
  )

  const onDrop = useCallback(
    async (acceptedFiles: any[], _: any[]) => {
      if (!user) return

      if (!acceptedFiles.length) {
        dispatch(setNotificationAction(NotificationId.IncorrectFileType))
        return
      }

      console.log(`Accepted ${acceptedFiles.length} files.`)
      const formData = new FormData()
      acceptedFiles.forEach((file: any) => formData.append('files[]', file))
      dispatch(updateEnterpriseLogoAction(accessToken, user.id, user.organization.enterprise.id, formData))
    },
    [user]
  )

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: ['.jpg', '.png', '.jpeg'] })

  return (
    <Layout>
      <MainBox defaultPadding>
        <h4 className="bold">Settings</h4>

        {isAdmin(role) && (
          <div>
            <p className="subheader">enterprise</p>

            <div>
              <p className="text-s bold mb-0">Logo</p>
              <p className="mb-1 text-s">Your logo appears at the top of your reports.</p>
              <div style={{ outline: 'none' }} {...getRootProps()} className="width-400px height-200px">
                {user?.organization.enterprise.logo_aws_s3_url ? (
                  <img
                    className="mh-100 mw-100 p-1 w-auto h-auto border rounded"
                    src={user?.organization.enterprise.logo_aws_s3_url}
                  />
                ) : (
                  <div className="border-upload h-100 w-100 rounded p-4 flex-center flex-column text-center">
                    <input {...getInputProps()} />

                    <div className="mt-1 mb-4 text-center">
                      <h3 className="text-xl mt-3 mb-2 bold text-primary">Upload Logo</h3>
                    </div>

                    <Button color="primary">Upload</Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {user && (
          <Formik
            initialValues={{
              audioAlertsOn: user.audio_alerts_on,
              displayPricing: user.organization.display_pricing,
              hospital: user.organization.display_name,
              pimsEmail: user.organization.pims_email,
              phoneNumber: user.organization.primary_phone_number,
              defaultTurnaroundHours: String(user.organization.enterprise.default_turnaround_hours),
            }}
            onSubmit={handleFormSubmit}
            validationSchema={Yup.object().shape({
              hospital: Yup.string().max(100, 'Must be under 100 characters.').required('Cannot be blank.'),
              pimsEmail: Yup.string().email('Must be a valid email.').nullable(),
              phoneNumber: Yup.string().matches(phoneRegExp, 'Must be a valid phone number.'),
            })}
          >
            {(formik) => {
              return (
                <Form style={{ margin: '20px 0' }}>
                  <p className="subheader">workspace</p>

                  {isVet(role) && <ConsultationTypes />}

                  {isUser(role) && (
                    <div className="mb-3">
                      <FormikInput label="Hospital name" name="hospital" placeholder="Hospital name" type="text" />
                    </div>
                  )}

                  {isUser(role) && (
                    <div className="mb-3">
                      <FormikInput
                        label="Phone number"
                        subLabel="Give our specialists a number to call for questions regarding your patients."
                        name="phoneNumber"
                        placeholder="(000) 000-0000"
                        type="text"
                      />
                    </div>
                  )}

                  {isUser(role) && (
                    <div className="mb-3">
                      <FormikInput label="PIMS diagnostics email" name="pimsEmail" placeholder="contact@example.com" type="text" />
                    </div>
                  )}

                  <div className="mb-3">
                    <p className="text-s bold mb-0">Sound</p>

                    <p className="mb-1 text-s">
                      Play audio alerts when patient events occur (ex. a case is created / a consultation is completed).
                    </p>

                    <div className="d-flex align-items-center">
                      <CustomInput
                        id="audioAlertsOn"
                        checked={formik.values.audioAlertsOn}
                        className="m-0 custom-switch-light"
                        role="button"
                        type="switch"
                        onChange={() => formik.setFieldValue('audioAlertsOn', !formik.values.audioAlertsOn)}
                      />

                      <p className="text-s m-0">
                        Audio alerts are <span className="semibold">{formik.values.audioAlertsOn ? 'enabled' : 'disabled'}</span>
                      </p>
                    </div>
                  </div>

                  {(isUser(role) || isEnterpriseAdmin(role)) && (
                    <div className="mb-3">
                      <p className="text-s bold mb-0">Pricing visibility</p>

                      <p className="mb-1 text-s">Control whether prices of services are visible or hidden.</p>

                      <div className="d-flex align-items-center">
                        <CustomInput
                          id="displayPricing"
                          checked={formik.values.displayPricing}
                          className="m-0 custom-switch-light"
                          role="button"
                          type="switch"
                          onChange={() => formik.setFieldValue('displayPricing', !formik.values.displayPricing)}
                        />

                        <p className="text-s m-0">
                          Prices are <span className="semibold">{formik.values.displayPricing ? 'visible' : 'hidden'}</span>
                        </p>
                      </div>
                    </div>
                  )}

                  {isAdmin(role) && (
                    <FormikRadioButton
                      containerClassName="mb-3"
                      label="Default Turnaround Time"
                      subLabel="Default turnaround time for specialist reports."
                      name="defaultTurnaroundHours"
                      options={TURNAROUND_TIMES}
                    />
                  )}

                  <Button disabled={formik.isSubmitting || !formik.isValid} type="submit" color="primary" className="px-5 mt-3">
                    Save
                  </Button>
                </Form>
              )
            }}
          </Formik>
        )}

        {user && isUser(role) && isRadimalEnterprise && (
          <div>
            <p className="subheader">billing</p>

            <div className="mb-4">
              {user.related_accounts?.stripe_profile?.email && (
                <div className="my-3">
                  <p className="text-m m-0">
                    Your billing email is <span className="semibold">{user.related_accounts?.stripe_profile?.email}</span>.
                  </p>

                  <Button
                    onClick={() => {
                      dispatch(
                        insertOrganizationEventForUserAction(accessToken, OrganizationEvent.OpenedBilling, user?.organization.id)
                      )
                      dispatch(createCustomerPortalAction(user.related_accounts!.stripe_profile!.id!))
                    }}
                    color="primary"
                    className="mt-3"
                  >
                    Manage Subscriptions & Invoices
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}

        {user && isUser(role) && (
          <div>
            <p className="subheader">report sharing</p>

            <p className="text-m m-0">Manage email recipients for AI and radiologist reports.</p>

            <DistributionEmailsTable columns={columns} data={user.organization.distribution_emails} />
          </div>
        )}
      </MainBox>
    </Layout>
  )
}
